import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'

const devConfig = {
    apiKey: "AIzaSyAnhiLciUZExnsss4LEnGIaKpcpXIT9qtU",
    authDomain: "ntnu-tdt4140-gr2154-dev.firebaseapp.com",
    projectId: "ntnu-tdt4140-gr2154-dev",
    storageBucket: "dev-static.samspisnad.no",
    messagingSenderId: "768497325269",
    appId: "1:768497325269:web:dc77a6ceeebace368bfe5c",
    databaseURL: ""
}
const prodConfig = {
    apiKey: "AIzaSyDPDB1Ncc-WutEsUduqahFUYwi-KLImYNY",
    authDomain: "ntnu-tdt4140-gr2154.firebaseapp.com",
    projectId: "ntnu-tdt4140-gr2154",
    storageBucket: "static.samspisnad.no",
    messagingSenderId: "134167526277",
    appId: "1:134167526277:web:05061b799b798f57b8a2ec",
    databaseURL: ""
}
const stagingConfig = {
    apiKey: "AIzaSyDRHoloLfoD-cAaqO80qyophKI9M_hD5Cc",
    authDomain: "ntnu-tdt4140-gr2154-sandbox.firebaseapp.com",
    projectId: "ntnu-tdt4140-gr2154-sandbox",
    storageBucket: "ntnu-tdt4140-gr2154-sandbox.appspot.com",
    messagingSenderId: "261834774487",
    appId: "1:261834774487:web:52a35c2201e25629cf9d92",
    databaseURL: ""
}

const firebaseConfig = (() => {
    return prodConfig
})()

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//const analytics = firebase.analytics();

const functions = firebase.app().functions('europe-west1')

const firestore = firebase.app().firestore()

const storage = firebase.app().storage()

const auth = firebase.app().auth()

if (process.env.NODE_ENV !== 'production') {
    functions.useEmulator("localhost", 5001)
    auth.useEmulator("http://localhost:9099/")
    firestore.useEmulator('localhost', 8080)
}

export  {
    firebaseConfig, functions, firestore, storage, auth, firebase as default
}