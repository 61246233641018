import {Checkbox, FormControlLabel, FormGroup} from "@material-ui/core";
import {Doc} from "typesaurus";
import {Allergy} from "../../types";

interface allergyCheckboxesInterface {
    userAllergies:string[],
    setCheckboxState:React.Dispatch<React.SetStateAction<Map<string,boolean>>>,
    checkboxState:Map<string,boolean>,
    allAllergies:Doc<Allergy>[] | undefined
}

export default function AllergyCheckboxes(props:allergyCheckboxesInterface) {
    // Set a checkbox state for all allergies - default is an empty Map<string,boolean>

    // Save all allergies in a map first
    let stateMap = new Map<string, boolean>()
    if (props.allAllergies) {
        props.allAllergies.forEach(allergy => stateMap.set(allergy.ref.id, props.userAllergies.includes(allergy.ref.id)))
    }
    // If the statemap has been filled, but the checkbox state has not been initially set
    if (stateMap.size > 0 && props.checkboxState.size === 0) {
        props.setCheckboxState(stateMap)
    }

    // Toggle checked-state of allergy with key @key
    function updateCheckboxState(key: string) {
        // Update a non-state map with new data
        let currentStateMap = props.checkboxState
        currentStateMap.set(key, !currentStateMap.get(key))
        // Overwrite old states with new map
        props.setCheckboxState(new Map<string, boolean>(currentStateMap))
    }

    // Create helpful array of keys (allergy-ids) for rendering
    let allergyKeys = Array.from(props.checkboxState.keys()).sort()

    return (
        <div>
            <FormGroup row>
                {
                    allergyKeys.map(allergy =>
                        <FormControlLabel
                            key={allergy}
                            control={
                                <Checkbox
                                    checked={props.checkboxState.get(allergy)}
                                    name={allergy}
                                    color="primary"
                                    onChange={() => updateCheckboxState(allergy)}
                                />
                            }
                            label={allergy}
                        />
                    )
                }
            </FormGroup>
        </div>
    )
}