import React from "react"
import { collection } from "typesaurus"
import { Report, User, Dinner } from "../../types"
import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
    Container,
    Divider,
    CircularProgress
} from "@material-ui/core"
import { useParams } from "react-router"
import { useOnGet } from "@typesaurus/react"
import Navbar from "../components/Navbar"
import UserCard from "../components/UserCard"
import DinnerCard from "../components/DinnerCard"
import Footer from "../components/Footer";

const reports = collection<Report>('reports')
const users = collection<User>('users')
const dinners = collection<Dinner>('dinners')

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: "white",
            paddingBottom: 20,
        },
        Typography: {
            marginTop: "15px",
        },
        Container: {
            paddingTop: "20px",
            paddingBottom: "20px",
        },
        CircularSpinner: {
            display: "flex",
            margin: "50px auto",
        }
    }),
);

// Displays details about a specific reported dinner
export default function ReportedDinner() {
    const classes = useStyles();
    const { id } = useParams<{ id: string }>(); //Report id from url
    const [report, reportState] = useOnGet(reports, id);
    const [dinnerOwner, dinnerOwnerState] = useOnGet(users, report?.data.userID)
    const [dinner, dinnerState] = useOnGet(dinners, report?.data.dinnerID)

    if (reportState.loading || dinnerOwnerState.loading || dinnerState.loading) {
        return (
            <>
                <div className={classes.root}>
                    <Navbar backArrow />
                    <CircularProgress className={classes.CircularSpinner} />
                </div>
            </>
        )
    }
    return (
        <>
            <div className={classes.root}>
                <Navbar backArrow />
                <Container className={classes.Container}>
                    <form autoComplete="off">
                        <Typography variant={"h4"} className={classes.Typography}>
                            Middagsrapport
                        </Typography>
                        <Divider />
                        <Typography variant={"h5"} className={classes.Typography}>
                            Hvorfor rapporteres middagen?
                        </Typography>
                        <Typography variant={"body1"} className={classes.Typography}>
                            <i>{report?.data.reportReason}</i>
                        </Typography>
                    </form>
                </Container>
                <Container className={classes.Container}>
                    <Grid container >
                    {(dinnerOwner) &&
                        <Grid item xs={12} md={8} lg={6} xl={4}>
                            <Typography variant={"h5"} className={classes.Typography}>Rapporteres av</Typography>
                            <UserCard fullWidth={true} user={dinnerOwner.data} owner={false} currentUserOwnsDinner={false} dinnerExpired={false} />
                        </Grid>
                    }
                    {(dinner) &&
                        <Grid item xs={12} md={5} xl={4}>
                            <Typography variant={"h5"} className={classes.Typography}>Middagen det gjelder</Typography>
                            <DinnerCard
                                id={dinner.ref.id}
                                key={dinner.ref.id}
                                title={dinner.data.title}
                                description={dinner.data.description}
                                location={dinner.data.location}
                                takenSeats={dinner.data.participants.length}
                                seats={dinner.data.seats}
                                time={dinner.data.time}
                                imageUrl={dinner.data.imgUrl}
                                expenses={dinner.data.expenses}
                                fullWidth={true}
                            />
                        </Grid>
                    }
                    </Grid>
                </Container>
            </div>
            <Footer />
        </>
    )
}