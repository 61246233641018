import React, {MouseEvent, useState} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {TextField, Grid, Typography} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import Menu from "@material-ui/core/Menu";
import {useAll} from "@typesaurus/react";
import {collection} from "typesaurus";


function valuetext(value: number) {
    return `${value} NOK`;
  }

  export interface FilterProps {
      onSearchChange: (value: string) => void
      onCostChange: (value: number[]) => void
      onAllergyChange: (value: boolean[]) => void
      onDateChange: (value: Date | null) => void
  }


function Filter (props: FilterProps) {
    const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: 300,
            },
            },
        checkbox: {
            margin: theme.spacing(1),
        },
        menu: {
            maxWidth: 350,
        },
        formControl: {
            margin: theme.spacing(1),
            width: 300,

          },
          chips: {
            display: 'flex',
            flexWrap: 'wrap',
          },
          chip: {
            margin: 2,
          },
          noLabel: {
            marginTop: theme.spacing(3),
          },
          slider: {
            width: 300,
            margin: theme.spacing(1),
            paddingTop: 40,
          },
          radiusSlider: {
            width: 300,
            margin: theme.spacing(1),
          },
          typography: {
            paddingTop: 20,
            paddingLeft: theme.spacing(1),
          },
          input: {
            width: 42,
          },
        }),
    );
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
        },
        };
    const [cost, setCost] = useState<number[]>([0, 100]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [allergies] = useAll(collection('allergies'))
    const classes = useStyles();
    const [allergiesFiltered, setAllergiesFiltered] = useState<string[]>([]);
    const handleDropdown = (event: React.ChangeEvent<{ value: unknown }>) => {
        setAllergiesFiltered(event.target.value as string[]);
        props.onAllergyChange(event.target.value as boolean[]);
    };
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
    const handleDateChange = (date: Date | null) => {
        if (!date || date < new Date()) {
            return
        }
        setSelectedDate(date);
        date && !isNaN(date.getTime()) && props.onDateChange(date);
    };
    const handleMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return(
        <div>
            <IconButton
                color="inherit"
                aria-haspopup="true"
                onClick={handleMenu}
            >
                <FilterListIcon/>
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                className={classes.menu}
            >
                <div>
                    <form noValidate autoComplete="off">
                        <Typography className={classes.typography} variant={"subtitle2"}><i>Merk: Filtreringen vil påvirke alle seksjoner</i></Typography>
                        <TextField
                            id="outlined-basic"
                            label="Søk etter middag"
                            variant="outlined"
                            className={classes.root}
                            onChange={e => props.onSearchChange(e.target.value)}
                        />
                    </form>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-mutiple-checkbox-label">Ekskluder allergener...</InputLabel>
                        <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            value={allergiesFiltered}
                            onChange={handleDropdown}
                            input={<Input />}
                            renderValue={(selected) => (selected as string[]).join(', ')}
                            MenuProps={MenuProps}
                        >
                            {allergies?.map((name) => (
                                <MenuItem key={name.ref.id} value={name.ref.id}>
                                    <Checkbox checked={allergiesFiltered.indexOf(name.ref.id) > -1} />
                                    <ListItemText primary={name.ref.id} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Typography className={classes.typography} id="range-slider" gutterBottom>
                        Pris per person...
                    </Typography>
                    <Slider
                        value={cost}
                        onChange={(e, newValue) => setCost(newValue as number[])}
                        onChangeCommitted={(e, newValue) => props.onCostChange(newValue as number[])}
                        valueLabelDisplay="on"
                        aria-labelledby="range-slider"
                        getAriaValueText={valuetext}
                        className={classes.slider}
                        max={250}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container className={classes.root}>
                            <KeyboardDatePicker
                                disablePast
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Fra dato..."
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                </div>
            </Menu>
        </div>
    )
}


export default Filter;