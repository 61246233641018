import './firebase';
import { BrowserRouter as Router, Route, RouteProps, Switch } from "react-router-dom";
import Home from "./home/Home";
import Profile from './profile/Profile';
import ProfileEdit from './profileEdit/ProfileEdit';
import Dinner from './dinner/Dinner';
import ReportDinner from './dinner/ReportDinner';
import AuthPage from "./auth/AuthPage";
import RegisterForm from "./auth/RegisterForm";
import LoginForm from "./auth/LoginForm";
import AdminPanel from './adminPanel/AdminPanel';
import ReportedDinner from "./dinner/ReportedDinner";
import { auth } from "./firebase";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Redirect } from "react-router";
import ManageDinner from './manageDinner/ManageDinner';
import { useAuthState } from "react-firebase-hooks/auth";
import CircularProgressDelayed from "./components/CircularProgressDelayed";
import {createStyles, makeStyles, MuiThemeProvider, Theme} from "@material-ui/core/styles";
import {createMuiTheme} from "@material-ui/core";
import ChatPage from "./chat/Chat";
function App() {
    const mainColor = "#123456"
    const secondaryColor = "#2e4f70"
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: mainColor,
            },
            secondary: {
                main: secondaryColor,
            },
        },
    })
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                minHeight: "100vh",
                backgroundColor: mainColor,
            },
        }),
    );
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <CssBaseline />
            <MuiThemeProvider theme={theme}>
            <Router>
                <Switch>
                    <AuthenticatedRoute path="/profile/:id" exact>
                        <Profile />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/profileEdit" exact>
                        <ProfileEdit />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/createDinner" exact>
                        <ManageDinner isCreated={true} />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/dinner/:id">
                        <Dinner />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/editDinner/:id" exact>
                        <ManageDinner isCreated={false} />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/reportDinner/:id" exact>
                        <ReportDinner />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/reportedDinner/:id" exact>
                        <ReportedDinner />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/adminPanel" exact>
                        <AdminPanel />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path="/chat">
                        <ChatPage/>
                    </AuthenticatedRoute>
                    <UnAuthenticatedRoute path="/register" exact redirect="/profileEdit">
                        <AuthPage text="Registrer deg">
                            <RegisterForm />
                        </AuthPage>
                    </UnAuthenticatedRoute>
                    <UnAuthenticatedRoute path="/login" exact>
                        <AuthPage text="Logg inn">
                            <LoginForm />
                        </AuthPage>
                    </UnAuthenticatedRoute>
                    <AuthenticatedRoute path="/">
                        <Home />
                    </AuthenticatedRoute>
                </Switch>
            </Router>
            </MuiThemeProvider>
        </div>
    );
}

export default App;

interface AuthRouteProps extends RouteProps {
    redirect?: string
}

function AuthenticatedRoute({ children, redirect = "/login", ...rest }: AuthRouteProps) {
    const [user, loading, error] = useAuthState(auth)
    error && console.error(error)
    return (
        <Route {...rest}>
            {loading && <CircularProgressDelayed />}
            {((!user && !loading) || error) && <Redirect to={redirect} />}
            {user && children}
        </Route>
    )
}

function UnAuthenticatedRoute({ children, redirect = "/", ...rest }: AuthRouteProps) {
    const [user, loading, error] = useAuthState(auth)
    error && console.error(error)
    return (
        <Route {...rest}>
            {loading && <CircularProgressDelayed />}
            {(user && !loading) && <Redirect to={redirect} />}
            {!user && children}
        </Route>
    )
}

