import { useEffect, useState } from "react";
import {useHistory, useParams} from "react-router-dom"
import {
    Button,
    Grid,
    Card,
    CardMedia,
    TextField,
    Typography, Checkbox, FormControlLabel, Tooltip,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom"
import Navbar from "../components/Navbar"
import MenuSlider from "./MenuSlider"
import {collection, field, ref, update, remove, add} from "typesaurus";
import {Allergy, Dinner, User} from "../../types";
import {useAll, useGetMany, useOnGet} from "@typesaurus/react";
import DateFnsUtils from '@date-io/date-fns'
import {
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ButtonWithLoading from "../components/ButtonWithLoading";
import Footer from "../components/Footer";
import firebase from "../firebase";


const dinners = collection<Dinner>('dinners')

export default function ManageDinner(props: {isCreated:boolean}) {
    const history = useHistory()
    const {id} = useParams<{id: string}>();
    const [dinner] = useOnGet(dinners, id);
    //retrive logged in user
    const users = collection<User>('users')
    let loggedInUser = firebase.auth().currentUser
    let [user] = useOnGet(users, loggedInUser ? loggedInUser.uid : undefined)
    
    // Retrieve participant IDs and get participants
    const userIds: string[] = dinner !== undefined && dinner !== null ? dinner.data.participants.map(v => v ? v.id: "") : []
    const [participants] = useGetMany(users, userIds)
    
    // Retrieve all allergies
    const allergies = collection<Allergy>('allergies')
    let [allAllergies] = useAll(allergies) // Get all allergies in collection
    let numberOfParticipants = 2
    if (dinner && dinner.data) {
        numberOfParticipants = dinner.data.participants.length
    }
    let [loading, setLoading] = useState(false)
    let [dinnername, setDinnername] = useState("")
    let [description, setDescription] = useState("")
    let [expenses, setExpenses] = useState(0)
    let [address, setAddress] = useState("")
    let [url, setUrl] = useState("")
    const errorImgUrl = "https://safetyaustraliagroup.com.au/wp-content/uploads/2019/05/image-not-found.png"
    let [isValidImg, setIsValidImg] = useState(true)
    let [seats, setSeats] = useState<number | string | number[]>(2); // seats
    let [datetime, setDatetime] = useState<Date>(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
    let [allergyCheckboxState, setAllergyCheckboxState] = useState(new Map<string, boolean>()); // allergies
    let [errors, setErrors] = useState(new Map<string, string>([ // Map containing error messages for specific fields
        ["title", ""],
        ["address", ""],
        ["url", ""],
        ["description", ""],
        ["expenses", ""]
    ]))
    const imagePreviewSize = "250px"
    let styles = {
        Root: {
            backgroundColor: "white",
            paddingBottom: 10,
        },
        GridContainer: {
            margin: "auto",
            marginTop: "20px",
            marginBottom: "15px",
        },
        GridItem: {
            margin: "5px 0 3px",
            padding: 10
        },
        Typography: {
            marginTop: "15px",
        },
        Button: {
            marginRight: "10px",
            marginTop: "5px"
        },
        Link: {
            textDecoration: "none"
        },
        Textfield: {
            marginTop: 5
        },
        ImagePreview: {
            width: imagePreviewSize,
            height: url === "" ? 0 : imagePreviewSize,
            margin: "auto",
            boxShadow: "none",
            backgroundColor: "rgba(0,0,0,0)",
            borderRadius: 5
        }
    }
    // If the dinner is being edited, fill out the text-fields with existing dinner data
    useEffect( () => {
        if (dinner && allAllergies && !props.isCreated){

            setDinnername(dinner.data.title)
            setDescription(dinner.data.description)
            setAddress(dinner.data.location)
            setUrl(dinner.data.imgUrl)
            setSeats(dinner.data.seats)
            setDatetime(dinner.data.time)
            setExpenses(dinner.data.expenses)
            // set allergies
            let allergies = dinner.data.allergies.map(allergy => allergy.id)
            let stateMap = new Map<string, boolean>()
            allAllergies.forEach(allergy => {
                stateMap.set(allergy.ref.id, allergies.includes(allergy.ref.id))
            })
            setAllergyCheckboxState(stateMap)
        }
    }, [dinner, allAllergies, props.isCreated])

    // Toggle checked-state of allergy with key @key
    function updateCheckedAllergies(key: string) {
        // Update a non-state map with new data
        let currentStateMap = allergyCheckboxState
        currentStateMap.set(key, !currentStateMap.get(key))
        // Overwrite old states with new map
        setAllergyCheckboxState(new Map<string, boolean>(currentStateMap))
    }
    // If the dinner is being created and all allergies have been retrieved, set up the checkbox state map
    useEffect(() => {
        if (allAllergies && props.isCreated) {
            let stateMap = new Map<string, boolean>()
            allAllergies.forEach(allergy => {
                stateMap.set(allergy.ref.id, false)
            })
            setAllergyCheckboxState(stateMap)
        }
    }, [allAllergies, props.isCreated])

    // Must update the corrensponding dinner in firestore
    function submit() {
        setLoading(true)
        let errorMap = new Map<string, string>(errors)
        let isValidInput = true
        // Get checked allergies and map them to Ref<Allergy> objects.
        let checkedAllergyArr = Array.from(allergyCheckboxState.keys()).filter(key => allergyCheckboxState.get(key)).map(key => ref<Allergy>(allergies, key))
        // Control input
        if (dinnername.length < 2) {
            errorMap.set("title", "Tittel må være minst 5 tegn lang")
            isValidInput = false
        } else {
            errorMap.set("title", "")
        }

        if (address.length < 5) {
            errorMap.set("address", "Skriv inn et gyldig sted (minst 5 tegn)")
            isValidInput = false
        } else {
            errorMap.set("address", "")
        }

        if (url.length < 5) {
            errorMap.set("url", "Middagen må ha et bilde")
            isValidInput = false
        } else if (!isValidImg) {
            errorMap.set("url", "Du må sette inn et gyldig bilde")
            isValidInput = false
        }
        else {
            errorMap.set("url", "")
        }

        if (description.length < 10) {
            errorMap.set("description", "Middagen trenger en beskrivelse")
            isValidInput = false
        } else {
            errorMap.set("description", "")
        }

        if (expenses < 0) {
            errorMap.set("expenses", "Utgiftene må være et positivt tall")
            isValidInput = false
        } else {
            errorMap.set("expenses", "")
        }
        setErrors(errorMap)

        // If the dinner is being edited
        if (isValidInput) {
            if (!props.isCreated) {
                const dinner = {
                    seats: +seats,
                    time: datetime,
                    expenses: isNaN(expenses) ? 0 : expenses,
                    title: dinnername,
                    description: description,
                    location: address,
                    imgUrl: url,
                    allergies: checkedAllergyArr,
                }
                updateDinner(dinner)
            } else {
                const dinner = {
                    id: "",
                    participants: [ref(users, user!.data.id)],
                    seats: +seats,
                    time: datetime,
                    expenses: isNaN(expenses) ? 0 : expenses,
                    title: dinnername,
                    description: description,
                    location: address,
                    imgUrl: url,
                    owner: ref(users, user!.data.id),
                    allergies: checkedAllergyArr,
                }
                createDinner(dinner)
            }
        }
        else { // If invalid input
            setLoading(false)
        }
    }

    function updateDinner(dinner:any) {
        const dinners = collection<Dinner>('dinners')
        update(dinners, id, dinner).then(() => {
            setLoading(false)
            history.push("/")
        }).catch((error) => {
            window.alert(error.message)
            setLoading(false)
        })
    }

    function createDinner(dinner:Dinner) {
        const dinners = collection<Dinner>('dinners')
        add(dinners, dinner).then((createdDinner) => {
            // Add the dinner to the user's dinners-array
            update(users, loggedInUser!.uid, [field('dinners', [...user!.data.dinners, createdDinner])])
                .then(() => {
                    // Save dinner.ref.id in dinner.data.id
                    update(dinners, createdDinner.id, [field('id', createdDinner.id)]).catch(error => console.log("Couldn't update dinner id because of error: " + error))
                    history.push("/")
                }).catch(error => {
                console.error(error)
                window.alert(error.message)
                setLoading(false)
            })
        }).catch((error) => {
            window.alert(error.message)
            setLoading(false)
        })
    }

    
    function deleteDinner() {
        setLoading(true)
        const dinners = collection<Dinner>('dinners')
        const dinnerRef = ref(dinners, id)
        participants?.forEach( participant => {
             // Update the participants' joinedDinners attribute
             if (participant.data.joinedDinners.length !== 0){
                update(users, participant.data.id, [field('joinedDinners', participant.data.joinedDinners.filter(v => v?.id !==dinnerRef.id))] )
             }
         })
        remove(dinners, id)
        .then(() => {
            history.push("/")
        }).catch(error => {
            console.error(error)
            window.alert(error.message)
            setLoading(false)
        })
    }

    // Create helpful array of keys (allergy-ids) for rendering
    let allergyKeys = Array.from(allergyCheckboxState.keys()).sort()
    return (
        <>
        <div style={styles.Root}>
            <Navbar backArrow />
            <Grid item lg={5} xs={11} style={styles.GridContainer} >
            <form noValidate autoComplete="off">
                <Typography variant={"h4"} style={styles.Typography}>
                    {props.isCreated ? "Opprett Middagsarrangement" : "Rediger Middagsarrangement"}
                </Typography>
                {/* Title */}
                <Grid item lg={10} style={styles.GridItem}>
                    <Typography variant={"body1"} style={styles.Typography}>
                        Jeg vil lage...
                    </Typography>
                    <TextField
                        variant="outlined"
                        label={"Tittel"}
                        color={"primary"}
                        style={styles.Textfield}
                        value={dinnername}
                        onChange={(e) => setDinnername(e.target.value)}
                        error={errors.get("title") !== ""}
                        helperText={errors.get("title")}
                        required fullWidth />
                </Grid>
                {/* Description */}
                <Grid item lg={10} style={styles.GridItem}>
                    <Typography variant={"body1"} style={styles.Typography}>
                        Beskrivelse av maten jeg vil lage...
                    </Typography>
                    <TextField
                        variant="outlined"
                        label={"Beskrivelse"}
                        color={"primary"}
                        style={styles.Textfield}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth multiline
                        rows={5}
                        inputProps={{ maxLength: 500 }}
                        required
                        error={errors.get("description") !== ""}
                        helperText={errors.get("description")}
                    />
                </Grid>
                {/* Expenses */}
                <Grid item lg={10} style={styles.GridItem}>
                    <Typography variant={"body1"} style={styles.Typography}>
                        Pris per deltaker (NOK)...
                    </Typography>
                    <TextField
                        variant="outlined"
                        type="number"
                        label={"Pris"}
                        color={"primary"}
                        style={styles.Textfield}
                        value={isNaN(expenses) ? "" : expenses}
                        onChange={(e) => {
                            let price = isNaN(Number(e.target.value)) ? 0 : Number(e.target.value)
                            if (price >= 0) {
                                setExpenses(parseInt(e.target.value))
                            }
                        }}
                        error={errors.get("expenses") !== ""}
                        helperText={errors.get("expenses")}
                        required fullWidth
                    />
                </Grid>
                {/* Image */}
                <Grid item lg={10} style={styles.GridItem}>
                    <Typography variant={"body1"} style={styles.Typography}>
                        URL til et bilde av retten jeg ønsker å lage...
                    </Typography>
                    <TextField
                        variant="outlined"
                        label={"Bilde-URL"}
                        color={"primary"}
                        style={styles.Textfield}
                        value={url}
                        onChange={(e) => {
                            setUrl(e.target.value)
                            setIsValidImg(true)
                        }}
                        error={errors.get("url") !== ""}
                        helperText={errors.get("url")}
                        required fullWidth />
                    <Typography variant={"body1"} style={styles.Typography} color={url ? "textPrimary" : "textSecondary"}>
                        Forhåndsvisning av bilde:
                    </Typography>
                    <Card style={styles.ImagePreview}>
                        <CardMedia
                            style={styles.ImagePreview}
                            src={url === "" ? errorImgUrl : url}
                            component={"img"}
                            // @ts-ignore
                            onError={e => {
                                e.target.src = errorImgUrl
                                setIsValidImg(false)
                            }}
                        />
                    </Card>
                </Grid>
                {/* Allergies */}
                <Grid item lg={10} style={styles.GridItem}>
                    <Typography variant={"body1"} style={styles.Typography}>
                        Allergener og preferanser...
                        <Tooltip title="Dette vil avgjøre hvilke brukere som får se arrangementet ditt" placement={"top"}>
                            <InfoOutlinedIcon color={"primary"} />
                        </Tooltip>
                    </Typography>
                    {allergyKeys.map(allergy =>
                        <FormControlLabel
                            key={allergy}
                            control={
                                <Checkbox
                                    checked={allergyCheckboxState.get(allergy)}
                                    name={allergy}
                                    color="primary"
                                    onChange={() => updateCheckedAllergies(allergy)}
                                />
                            }
                            label={allergy}
                        />
                    )}
                </Grid>
                {/* Address */}
                <Grid item lg={10} style={styles.GridItem}>
                    <Typography variant={"body1"} style={styles.Typography}>
                        Hvor er middagen?
                    </Typography>
                    <TextField
                        variant="outlined"
                        label={"Adresse"}
                        color={"primary"}
                        style={styles.Textfield}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        error={errors.get("address") !== ""}
                        helperText={errors.get("address")}
                        required fullWidth />
                    <Button variant={"contained"} color={"primary"} style={styles.Button} onClick={() => {
                        setAddress(user!.data.address ? user!.data.address : "")
                    }}>
                        Sett inn lagret addresse
                    </Button>
                </Grid>
                {/* Time */}
                <Grid item lg={10} style={styles.GridItem}>
                    <Typography variant={"body1"} style={styles.Typography}>
                        Arrangementet vil avholdes...
                    </Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                            variant="inline"
                            format="dd/MM/yyyy HH:mm"
                            ampm={false}
                            id="datetime-local"
                            label="Dato og tidspunkt"
                            style={styles.Textfield}
                            value={datetime}
                            // @ts-ignore
                            onChange={(e) => setDatetime(e)}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                {/* Number of places */}
                <Grid item lg={10} style={styles.GridItem}>
                    <Typography variant={"body1"} style={styles.Typography}>
                        Det er plass til...
                    </Typography>
                    <MenuSlider value={seats} setValue={setSeats} minValue={numberOfParticipants}/>
                </Grid>
                {/* Buttons */}
                <Grid item lg={10} style={styles.GridItem}>
                    <ButtonWithLoading isLoading={loading} variant="contained" color="primary" style={styles.Button} onClick={submit}>
                        {props.isCreated ? "Opprett" : "Oppdater"}
                    </ButtonWithLoading>
                    {
                        !props.isCreated && // If the dinner is being edited, allow deleting
                        <ButtonWithLoading isLoading={loading} variant="contained" color="secondary" style={styles.Button} onClick={deleteDinner}>
                            Slett middag
                        </ButtonWithLoading>
                    }
                    <RouterLink to={"/"} style={styles.Link}>
                        <Button variant="contained" color="default" style={styles.Button}>
                            Avbryt
                        </Button>
                    </RouterLink>
                </Grid>
            </form>
            </Grid>
        </div>
        <Footer />
        </>
    )
}

