import {RefObject, useEffect, useMemo, useState} from "react";

/**
 * Tells you if the element passed as a ref is on screen or not.
 * @param {ref<Element>} ref - The element to keep track of.
 * @param {IntersectionObserverInit} options - The options passed to IntersectionObserver.
 */
export default function useOnScreen<Element extends HTMLElement>(
        ref: RefObject<Element>,
        options?: {
            root?: RefObject<HTMLElement> | null,
            rootMargin?: string,
            threshold?: number,
        }
    ) {

    const [isIntersecting, setIntersecting] = useState(false)


    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        {root: options?.root?.current, rootMargin: options?.rootMargin, threshold: options?.threshold}
    ), [options])

    useEffect(() => {
        ref.current && (() => {
            observer.observe(ref.current)
        })()
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    }, [observer, ref])

    return isIntersecting
}