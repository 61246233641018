import {Grid, TextField, Link as MuiLink} from "@material-ui/core";
import {Link, useHistory} from 'react-router-dom'
import {makeStyles} from "@material-ui/core/styles";
import {MouseEvent, useState} from "react";
import {auth} from "../firebase";
import ButtonWithLoading from "../components/ButtonWithLoading";

const useStyles = makeStyles(theme =>({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))

export default function LoginForm() {
    auth.languageCode = 'no'
    const classes = useStyles()
    const history = useHistory()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    // When user clicks on "log in"
    const submit = (e: MouseEvent | KeyboardEvent) => {
        e.preventDefault()
        setLoading(true)
        auth.signInWithEmailAndPassword(email, password)
            .then(userCredentials => {
                window.alert("Logget inn med bruker " + userCredentials.user?.email)
                history.push("/")
            })
            .catch(error => {
                window.alert(error)
                setLoading(false)
            })
    }
    // When user clicks on "reset password"
    const resetPassword = (e: MouseEvent<HTMLSpanElement>) => {
        e.preventDefault()
        if (!email) {
            window.alert("Skriv inn epostadressen din først")
            return
        }
        auth.sendPasswordResetEmail(email)
            .then(() => {
                window.alert("Lenke er sendt til eposten din")
            })
            .catch(error => {
                window.alert(error.message)
            })
    }

    return (
        <form className={classes.form} noValidate>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Epostadresse"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={e => setEmail(e.target.value)}
                onKeyPress={e => e.key === "Enter" ? submit : null}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Passord"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={e => setPassword(e.target.value)}
                onKeyPress={e => e.key === "Enter" ? submit : null}
            />
            <ButtonWithLoading
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submit}
                isLoading={loading}
            >
                Logg inn
            </ButtonWithLoading>
            <Grid container spacing={8}>
                <Grid item>
                    <Link to="/register">
                        Ingen konto? Register deg
                    </Link>
                </Grid>
                <Grid item>
                    <MuiLink href="#" onClick={resetPassword}>
                        Glemt passord?
                    </MuiLink>
                </Grid>
            </Grid>
        </form>
    )
}