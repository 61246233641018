import {CircularProgress, Fade, Grid} from "@material-ui/core";

interface CircularProgressDelayedProps {
    alignVertical?: boolean
    delay?: string
}

export default function CircularProgressDelayed({alignVertical=true, delay='800ms'}: CircularProgressDelayedProps) {
    return (
        <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={alignVertical ? {minHeight: '100vh'} : {}}>
            <Grid item xs={3}>
                <Fade
                    in
                    style={{
                        transitionDelay: delay,
                    }}
                    unmountOnExit
                >
                    <CircularProgress />
                </Fade>
            </Grid>
        </Grid>
    )
}