import {
    AnyDoc,
    Collection,
    CollectionGroup, onQuery,
    OnQueryOptions,
    Query,
    RuntimeEnvironment,
    ServerTimestampsStrategy
} from "typesaurus";
import {TypesaurusHookResult} from "@typesaurus/react/types";
import {useEffect, useState} from "react";

/**
 * This is the same as typesaurus-react's useOnQuery, but which doesn't reset the result before loading the new one.
 * This removes annoying flickering as the whole list might disappear when trying to use the hook for an infinite scroll.
 * @param collection - The collection to query against.
 * @param queries - The queries.
 * @param options - Options to pass to doc.data().
 */
export default function useOnQueryWithoutReset<
    Model,
    Environment extends RuntimeEnvironment | undefined,
    ServerTimestamps extends ServerTimestampsStrategy
    >(
    collection: Collection<Model> | CollectionGroup<Model>,
    queries: Query<Model, keyof Model>[] | undefined,
    options?: OnQueryOptions<Environment, ServerTimestamps>,
    ): TypesaurusHookResult<
    typeof queries extends undefined
        ? undefined
        : AnyDoc<Model, Environment, boolean, ServerTimestamps>[] | undefined
    > {

    const maybeSetResult = (res: AnyDoc<Model, Environment, boolean, ServerTimestamps>[] | undefined) => {
        if (res) {
            setResult(res)
            setLoading(false)
        }
    }

    const [result, setResult] = useState<
        AnyDoc<Model, Environment, boolean, ServerTimestamps>[] | undefined
        >(undefined)
    const [error, setError] = useState<unknown>(undefined)
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        if (queries) {
            setLoading(true)
            return onQuery(collection, queries, maybeSetResult, setError, options)
        }
        // We want slightly different effects for the query changing and the collection changing
        // For the first, we want to keep the state
        // For the second, we want to blank it first.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(queries), JSON.stringify(collection), JSON.stringify(options)])

    return [result, { loading: loading && !error, error }]
}